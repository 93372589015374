<template>
  <div class="home-brand">
    <div class="swiper-container brand">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in dataList" :key="index">
          <div class="card-content grid grid-rows-3 grid-cols-5 gap-6">
            <div class="card flex align-center justify-center" v-for="(_item, _index) in item.children" :key="_index">
              <img :src="_item.url" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';

export default {
  name: 'HomeWelfareSwiper',
  data() {
    return {
      swiperInstance: null,
      dataList: [
        {
          children: [
            {
              url: require('@/assets/img/customer/1.png')
            },
            {
              url: require('@/assets/img/customer/2.png')
            },
            {
              url: require('@/assets/img/customer/3.png')
            },
            {
              url: require('@/assets/img/customer/4.png')
            },
            {
              url: require('@/assets/img/customer/5.png')
            },
            {
              url: require('@/assets/img/customer/6.png')
            },
            {
              url: require('@/assets/img/customer/7.png')
            },
            {
              url: require('@/assets/img/customer/8.png')
            },
            {
              url: require('@/assets/img/customer/9.png')
            },
            {
              url: require('@/assets/img/customer/10.png')
            },
            {
              url: require('@/assets/img/customer/11.png')
            },
            {
              url: require('@/assets/img/customer/12.png')
            },
            {
              url: require('@/assets/img/customer/13.png')
            },
            {
              url: require('@/assets/img/customer/14.png')
            },
            {
              url: require('@/assets/img/customer/15.png')
            }
          ]
        },
        {
          children: [
            {
              url: require('@/assets/img/customer/16.png')
            },
            {
              url: require('@/assets/img/customer/17.png')
            },
            {
              url: require('@/assets/img/customer/18.png')
            },
            {
              url: require('@/assets/img/customer/19.png')
            },
            {
              url: require('@/assets/img/customer/20.png')
            },
            {
              url: require('@/assets/img/customer/21.png')
            },
            {
              url: require('@/assets/img/customer/22.png')
            },
            {
              url: require('@/assets/img/customer/23.png')
            },
            {
              url: require('@/assets/img/customer/24.png')
            },
            {
              url: require('@/assets/img/customer/25.png')
            },
            {
              url: require('@/assets/img/customer/26.png')
            },
            {
              url: require('@/assets/img/customer/27.png')
            },
            {
              url: require('@/assets/img/customer/28.png')
            },
            {
              url: require('@/assets/img/customer/29.png')
            },
            {
              url: require('@/assets/img/customer/30.png')
            }
          ]
        }
      ]
    };
  },
  mounted() {
    this.swiperInstance = new Swiper('.brand', {
      //   loop: true,
      autoplay: {
        delay: 5000,
        stopOnLastSlide: false,
        disableOnInteraction: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    });
  }
};
</script>
<style scoped lang="less">
.home-brand {
  padding: 20px;
  position: relative;
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    position: absolute;
    left: -30px;
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-next {
    position: absolute;
    right: -30px;
  }
  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 20px;
  }
}
.swiper-container {
  position: relative;
  .swiper-slide {
    height: 400px;
    width: 100%;
  }
  .swiper-pagination /deep/ .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 0 8px;
    margin-top: 20px;
  }
  .swiper-pagination /deep/ .swiper-pagination-bullet-active {
    width: 30px;
    height: 12px;
    background: rgba(60, 102, 239, 1) !important;
    border-radius: 9px;
  }
  .card-content {
    .card {
      width: 220px;
      height: 108px;
      //   background-color: red;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 3px 10px rgba(34, 78, 219, 0.1);
      img {
        max-width: 123px;
        max-height: 55px;
      }
    }
  }
}
</style>
